import React from "react";
import CSTheme from "../assets/CSTheme";

const sbvds = {
        Color: CSTheme.colors.PRIMARY,
        key: 'vn1',
        id: 1,
        ProductName: 'SBVDS Institutions',
        HeaderTitle: 'Vidyodaya',
        SubTitle1: 'Experience the Future of ',
        SubTitle2: 'App Development',
        SubTitle3: 'V Subtitle 3',
        Image: "/image.png",
        Logo: "web/photos/logo.png",
        PhoneNumber: '+91-9398903833',
        Email: 'sbvdsgroup@gmail.com',
        weburl: 'https://www.google.com',       
        Banner1: '/web/photos/Banner2.jpg',
        Banner2: '/web/photos/Banner3.jpg',
        Banner3: '/web/photos/Banner1.jpg',

        // MenuItems
        Home: 'Home',
        Menu1: 'About Us',
        Menu2: 'Gallery',
        Menu3: 'Blogs',
        Menu4: 'Admin',
        Menu5: 'Contact Us',
        Button1: 'Login',
        Button2: 'Register',
};

export const DataContext = React.createContext();

export const DataContextProvider = ({ children }) => {
        return (
                <DataContext.Provider value={sbvds}>{children}</DataContext.Provider>
        );
};
