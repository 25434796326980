import React, { useEffect, useState } from 'react'
import CS_DataTable from '../Screens/CS_DataTable'
import CSDropdown from '../controls/CSDropdown'
import { Dropdown } from 'primereact/dropdown';
import CSRequestUtil from '../util/CSRequestUtil';
import { DataTable } from 'primereact/datatable';
import CSServiceUtil from '../util/CSServiceUtil';
import CSCOBackdrop from '../controls/CSCOBackdrop';
import { Column } from 'primereact/column';

export default function CS_Alumni() {
    const [mAYYear, setAYYear] = useState()
    const [mSelectedAyYear, setSelectedAyYear] = useState()
    const [mAllAlumni, setAllAlumni] = useState([])
    const [mShowBackdrop, setShowBackdrop] = useState(false);
    useEffect(() => {
        const fnGetAyYearList = () => {
            let lServiceCode = 'GET_ACADEMIC_YEAR';
            setShowBackdrop(true);
            let lCriteria = {}
            let lRequestUtil = new CSRequestUtil('GET', lServiceCode, 'academic_year');
            lRequestUtil.cfSetRequestCriteria(lCriteria);
            lRequestUtil.cfProcessRequest((lResponse) => {
                if (lResponse) {
                    if (lResponse.hasOwnProperty('collection_')) {
                        setAYYear(lResponse.collection_);
                    }
                    setShowBackdrop(false);
                }
            }
            );
        }

        fnGetAyYearList();
    }, [])

    useEffect(() => {
        const fnGetAlumniStudents = () => {
            if (mSelectedAyYear && mSelectedAyYear.academic_year_code_) {
                let lServiceCode = 'GET_ALUMNI_STUDENT';
                setShowBackdrop(true);
                let lCriteria = {
                    academic_year_: mSelectedAyYear.academic_year_code_,
                };
                let lRequestUtil = new CSRequestUtil('GET', lServiceCode, 'alumni_student');
                lRequestUtil.cfSetRequestCriteria(lCriteria);
                lRequestUtil.cfProcessRequest((lResponse) => {
                    if (lResponse) {
                        if (lResponse.hasOwnProperty('collection_')) {
                            setAllAlumni(lResponse.collection_);
                        }
                        setShowBackdrop(false);
                    }
                });
            }
        };
        fnGetAlumniStudents();
    }, [mSelectedAyYear]
    )

    const imageTemplate = (student) => {
        let lLocation = "https://www.sbvds.org/data/others/" + student.photo_file_;
        return <img src={lLocation} width={30} />;
    };

    return (
        <div className='flex flex-column justify-content-center bg-blue-100 pb-6'>
            <div className="col-12 text-center text-2xl font-bold text-900">SVBD Alumni List </div>
            <div className='csunderline mb-5'></div>
            <div className='alumni flex flex-wrap justify-content-center align-items-center mb-3 '>
                {/* <span id='ay_year' className='pr-3 font-semibold text-900'> Academic Year</span> */}
                <Dropdown
                    value={mSelectedAyYear}
                    onChange={(e) => setSelectedAyYear(e.value)}
                    options={mAYYear}
                    filter
                    optionLabel="academic_year_name_"
                    placeholder="Select a Passed out Year"
                    className="w-full md:w-19rem"
                />
            </div>
            <div className='px-2 md:px-8'>
                <DataTable
                    value={mAllAlumni}
                    width="100%"
                    showGridlines
                    emptyMessage="No Records Found"
                >
                    <Column
                        header="PHOTO"
                        body={imageTemplate}
                        className="flex justify-content-center"
                    />
                    <Column
                        field="academic_year_name_"
                        header="Passed Out Year"
                    />
                    <Column
                        field="student_name_"
                        header="Student Name"
                    />
                    <Column
                        field="class_"
                        header="Class"
                    />
                    <Column
                        field="section_"
                        header="Section"
                    />
                    <Column
                        field="occupation_"
                        header="Occupation"
                    />
                </DataTable>
            </div>
        </div>
    )
}
