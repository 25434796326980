import { useContext } from 'react';
import React from 'react';
import { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import C_Navbar from './C_Navbar';
import { DataContext } from '../context/DataContext';
import VGROUP from '../layout/groups/VGROUP';
import HGROUP from '../layout/groups/HGROUP';
import CS_Navbar from './C_Navbar';

function CS_Topbar() {

    const ProductData = useContext(DataContext);
    const ProductMenu =
        <>
           <CS_Navbar/>
        </>

    return (
        <VGROUP >
            <HGROUP justifyContent='space-between' display='inline'>
                {ProductMenu}
            </HGROUP>
            <Outlet />
        </VGROUP>
    )
}

export default CS_Topbar