import React, { useEffect, useRef, useState } from 'react'
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Navigate, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { fnGetCurrentDate } from '../util/CSDateTimeUtil';
import { InputText } from 'primereact/inputtext';
import { InputOtp } from 'primereact/inputotp';

import CSRequestUtil from '../util/CSRequestUtil';
import CSServiceUtil from '../util/CSServiceUtil';
import CSCOBackdrop from '../controls/CSCOBackdrop';


export default function CS_Blog() {

    let lDatesave = fnGetCurrentDate('save');
    let lDateshow = fnGetCurrentDate('display');
    const [mShowBackdrop, setShowBackdrop] = useState(false);
    const [mBlogHead, setBlogHead] = useState('');
    const [mBlogText, setBlogText] = useState('');
    const [mMobileNumber, setMobileNumber] = useState('');
    const [mPassword, setPassword] = useState('');
    const [mSecrectCode, setSecrectCode] = useState('');
    const [mBlogShow, setBlogShow] = useState(false);
    const [mCreateBlogShow, setCreateBlogShow] = useState(false);
    const lnavigate = useNavigate()
    const [mblogData, setBlogData] = useState([]);
    const [mToken, setTokens] = useState();
    const toast = useRef(null);

    useEffect(() => {
        fnReset();
    }, [])

    // function OpenBlog() {
    //     lnavigate("/blogpost")
    // }

    const fnReset = () => {
        setBlogHead(null)
        setBlogText(null)
        setMobileNumber(null)
        setTokens(null)
    }


    const fnHideDialog = () => {
        if (!mCreateBlogShow) return; setCreateBlogShow(false);
        fnReset();
    }

    useEffect(() => {
        const fnGetBlog = () => {
            let lServiceCode = 'GET_ALUMNI_BLOG';
            setShowBackdrop(true);
            let lCriteria = {}
            let lRequestUtil = new CSRequestUtil('GET', lServiceCode, 'alumni_blog');
            lRequestUtil.cfSetRequestCriteria(lCriteria);
            lRequestUtil.cfProcessRequest((lResponse) => {
                if (lResponse) {
                    if (lResponse.hasOwnProperty('collection_')) {
                        setBlogData(lResponse.collection_);
                    }
                    setShowBackdrop(false);
                }
            }
            );
        }
        fnGetBlog();
    }, [])

    const fnSaveBlog = () => {
        let lServiceCode = 'ADD_ALUMNI_BLOG_REQUEST';
        if (mBlogHead && mBlogText) {
            let lItem =
            {
                blog_head_: mBlogHead,
                blog_text_: mBlogText,
                mobile_number_: mMobileNumber,
                date_: lDatesave,
                passcode_: mToken,
                status_: 'ALM_BLG_REQ_SUBMIT'
            }
            let lRequestUtil = new CSRequestUtil('SAVE', lServiceCode, 'alumni_blog_request');
            if (lRequestUtil) {
                lRequestUtil.cfSetRequestData(lItem);
                lRequestUtil.cfProcessRequest((lResponse) => {
                    var lCode = null;
                    var lMsg = null;
                    if (lResponse && lResponse.code) {
                        if (lResponse.code instanceof Array) {
                            lCode = lResponse.code[0];
                            if (lResponse.message) {
                                lMsg = lResponse.message[0];
                            }
                        }
                        else {
                            lCode = lResponse.code;
                            if (lResponse.message) {
                                lMsg = lResponse.message;
                            }
                        }
                    }
                    else {
                        lCode = 'Error in Saving blog';
                    }

                    if ((lCode === '1') || (lCode === 1)) {
                        lMsg = 'Successfully Submitted Blog Request';
                        toast.current.show({ severity: 'success', summary: 'Success', detail: lMsg });
                    }
                    else if (lCode === '') {

                    }

                    fnHideDialog();
                });
            }
        }
    }

    // const fnBlogOpen =
    //     <Dialog
    //         visible={mBlogShow}
    //         header='Post a Blog'
    //         style={{ width: '50vw' }}
    //         breakpoints={{ '960px': '75vw', '641px': '100vw' }}
    //     >
    //         <div className="surface-card border-round p-1" >
    //             <label htmlFor="bloghead" className='Blog'>Blog Headline</label>
    //             <InputText
    //                 id='bloghead'
    //                 value={mBlogHead}
    //                 onChange={(e) => setBlogHead(e.target.value)}
    //                 required={true}
    //                 className='w-full mb-2 '
    //             />
    //         </div>
    //     </Dialog>

    // const ReadMore = ({ children }) => {
    //     const text = children;
    //     const [isReadMore, setIsReadMore] = useState(false);
    //     const toggleReadMore = () => {
    //         setIsReadMore(!isReadMore);
    //     };
    //     return (
    //         <div className="line-height-3 mb-3 text-700">
    //             {isReadMore ? text.slice(0, 800) : text}
    //             <span
    //                 onClick={toggleReadMore}
    //                 className="read-or-hide"
    //                 style={{ color: "green" }}
    //             >
    //                 {isReadMore ? "...read more" : " show less"}
    //             </span>
    //         </div>
    //     );
    // };


    const blogCard = mblogData.map((pblog) =>
        <div className="col-12 lg:col-3 p-3 bg-white" key={pblog.id_}>
            <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: '6px' }}>
                <div className="text-xl text-900 font-medium mb-1 line-height-3">{pblog.blog_head_}</div>
                <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 " />

                {/* <ReadMore>{pblog.blog_text_}</ReadMore> */}
                <div className="line-height-3 mb-3 text-700">{pblog.blog_text_}</div>
                <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                <div className="flex bg-white bottom-0 p-2">
                    <div className="ml-2">
                        <div className="text-sm font-bold text-900 mb-1">{pblog.student_name_}</div>
                        <div className="text-sm flex align-items-center text-700">
                            <i className="pi pi-calendar mr-1 text-sm"></i><span>{pblog.date_}</span></div>
                    </div>
                </div>
            </div>
        </div>
    );
    const mActions =
        <div className='p-2'>
            <Button label="Post" icon="pi pi-check" className='w-6rem' onClick={fnSaveBlog} />
            <Button label="Cancel" icon="pi pi-times" className='w-6rem mr-2 bg-primary-light' onClick={fnHideDialog} />
        </div>
    const mCreateBlog =
        <Dialog
            visible={mCreateBlogShow}
            header='Post a Blog'
            onHide={fnHideDialog}
            style={{ width: '50vw' }}
            breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            footer={mActions}>

            <div className="surface-card border-round p-1" >
                <label htmlFor="bloghead" className='Blog'>Blog Headline</label>
                <InputText
                    id='bloghead'
                    value={mBlogHead}
                    onChange={(e) => setBlogHead(e.target.value)}
                    required={true}
                    className='w-full mb-2 '
                />
                <InputTextarea value={mBlogText}
                    onChange={(e) => setBlogText(e.target.value)}
                    rows={5} cols={30} className='w-full mb-2' placeholder="Write Blog here" >
                </InputTextarea>
                <div className='flex gap-2 p-1 align-items-center'>
                    <label htmlFor='blogUserId' className='Blog'>Mobile Number</label>
                    <InputText
                        id='blogUserId'
                        value={mMobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        required={true}
                        className='w-5'
                    />
                    <label htmlFor='blogCode' className='Blog'>Secret Code</label>
                    {/* <InputText
                        id='blogCode'
                        value={mSecrectCode}
                        onChange={(e) => setSecrectCode(e.target.value)}
                        required={true}
                        className='w-3'
                    /> */}
                    <div className="flex justify-content-center">
                        <InputOtp value={mToken} onChange={(e) => setTokens(e.value)} />
                    </div>
                </div>
            </div>
        </Dialog>

    const pageBanner =
        <div className="">
            <div className="flex justify-content-end p-2 flex-column lg:flex-row mb-4">
                <div className="mt-3 right-0 lg:mt-0">
                    <Button label="Post A Blog" className="p-button mr-2" icon="pi pi-plus" onClick={() => setCreateBlogShow(true)} />
                </div>
            </div>
        </div>


    return (
        <div>
            <div className="surface-50 px-4 py-4 md:px-6 lg:px-4">
                <div className="font-bold text-5xl text-900 mb-5 text-center">Blogs</div>
                <CSCOBackdrop pshow={mShowBackdrop} />
                <Toast ref={toast} />
                {pageBanner}
                {mCreateBlog}
                <div className="grid nogutter bg-white">
                    {blogCard}
                </div>
            </div>
        </div>
    )
}
