import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
export default function CSDropdown(gProps) {

  const [mLabelStyle, setLabelStyle] = useState ('font-semibold')
  const [mChildren, setChildren] = useState(null);

  useEffect(() => {
    if (gProps) {
        /**
         * Initialize Component Styles
         */
        if(gProps.pRequired)
        {
            setLabelStyle('font-semibold required-field pr-2');
        }
        if (gProps.hasOwnProperty('children')) {
          setChildren(gProps.children);
      }
  }
}, [gProps])

  return (
    <div  className   =   {gProps.pClassName} style={{padding : gProps.pPadding}}>
      <div id={gProps.pLabelId} className={mLabelStyle}>
        {gProps.pControlLabel}  
      </div>
      <Dropdown
        aria-labelledby   =     {gProps.pLabelId}
        value             =     {gProps.pValue}
        onChange          =     {(e) => gProps.OnchangeValue(gProps.pLabelId, e.value)}
        options           =     {gProps.pOptions}
        name              =     {gProps.pValue}
        optionLabel       =     {gProps.pLabel}
        placeholder       =     {gProps.pPlaceHolder}
        required          =     {gProps.pRequired}
        style             =     {{width:gProps.pWidth}}
       
      />
                {mChildren}
    </div>
  )
}
