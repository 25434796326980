import React, { useContext, useEffect, useState } from 'react'
import CSRequestUtil from '../util/CSRequestUtil';
import { DataContext } from '../context/DataContext';

export default function CS_Requirments() {
    const [mReqData, setReqData] = useState([])
    const [mShowBackdrop, setShowBackdrop] = useState(false);
const ProductData = useContext(DataContext);
   const { PhoneNumber } = ProductData;
    useEffect(() => {
        const fnGetRequirements = () => {
            let lServiceCode = 'GET_INSTITUTION_REQUIRMENTS';
            setShowBackdrop(true);
            let lCriteria = {}
            let lRequestUtil = new CSRequestUtil('GET', lServiceCode, 'alumni_blog');
            lRequestUtil.cfSetRequestCriteria(lCriteria);
            lRequestUtil.cfProcessRequest((lResponse) => {
                if (lResponse) {
                    if (lResponse.hasOwnProperty('collection_')) {
                        setReqData(lResponse.collection_);
                    }
                    setShowBackdrop(false);
                }
            }
            );
        }
        fnGetRequirements();
    }, [])


    return (
        <div>
            <div className="text-900 text-center text-3xl py-4 font-bold">Requirment information</div>
            <div className="flex flex-wrap justify-content-center align-items-center px-4 gap-2">
                {mReqData.map((pData) => (
                    <div className="requirecard col-12 surface-card border-round shadow-3 p-5 mx-8 md:col-4">
                        <div className="flex flex-column justify-content-center lg:flex-row flex-wrap lg:align-items-center py-3 surface-border">
                            <div className="flex-auto lg:ml-3">
                                <div className="flex align-items-center justify-content-between mb-3">
                                    <span className="text-900 font-medium">{pData.requirement_text_}</span>
                                </div>
                            </div>
                        </div>
                        <div className="py-2 mt-3 border-bottom-1 surface-border">
                            <div className="flex justify-content-between align-items-center mb-3">
                                <span className="text-900">Budget</span>
                                <span className="text-900">₹ {pData.budget_}</span>
                            </div>
                            <div className="flex justify-content-between align-items-center mb-3">
                                <span className="text-900">Collected Amount</span>
                                <span className="text-900">₹ {pData.collected_}</span>
                            </div>
                            <div className="flex justify-content-between align-items-center mb-3">
                                <span className="text-900">Remaining Balance</span>
                                <span className="text-900 font-bold">₹ {pData.balance_}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='mt-4 px-3'>Note : If You Are Intrested In Donating Please <a href='/contact'>Contact Ph: {PhoneNumber}</a></div>
        </div>
    )
}
