import React, { useContext, useEffect, useState } from 'react'
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import { NavLink, useNavigate } from 'react-router-dom';

import { DataContext } from '../context/DataContext';
import { PhotoService } from '../services/PhotoService';
import CS_Alumni from './CS_Alumni';
import CS_Requirments from './CS_Requirments';

export default function CS_HomePage() {
   const [images, setImages] = useState(null);
   const ProductData = useContext(DataContext);
   const { ProductName, Logo, Home, Menu2, Menu3, Menu5, Color, PhoneNumber } = ProductData;
   const lnavigate = useNavigate()

   useEffect(() => {
      PhotoService.getPhotos().then((data) => setImages(data.slice(0, 3)));
   }, []);

   const responsiveOptions = [
      { breakpoint: '1199px', numVisible: 1, numScroll: 1 },
      { breakpoint: '991px', numVisible: 2, numScroll: 1 },
      { breakpoint: '767px', numVisible: 1, numScroll: 1 }
   ];

   const itemTemplate = (item) => (
      <img src={item.image} alt={item.description} style={{ width: '100%', display: 'block' }} />
   );

   function fnNavigateVNPride() {
      window.open("https://www.venobleinfra.com", "_blank");
   }

   return (
      <div>
         {/* Image Carousel */}
         <div className="lg:block">
            <Carousel value={images} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions}
               className="custom-carousel w-full mb-8" circular autoplayInterval={8000}
               itemTemplate={itemTemplate} showNavigators={false} showIndicators={true} />
         </div>

         {/* Content Section */}
         <div className="surface-ground px-4 py-5 md:px-6 lg:px-8">
            <div className="grid">
               <div className="col-12 lg:col-6 p-2">
                  <div className="shadow-2 surface-card border-round p-4 flex-1 text-center mb-6 md:mb-0">
                     <div className="mb-4">
                        <img src="web/photos/image_ (1).png" width="280" height="200" className="-mt-8 border-round" alt="img" />
                     </div>
                     <p className="text-base line-height-3 text-justify" style={{ letterSpacing: 'px' }}>
                        Sri Brahmottama Vidyadana Sabha School (SBVD Sabha School) was established in 1911 by Sri Nukala Venkata Subbaiah garu, with the generous support of donors. Situated in Pullampet Mandal, Annamayya district, Andhra Pradesh, our institution aims to provide quality education and nurture future leaders.
                     </p>
                  </div>
               </div>
               <div className="col-12 lg:col-6 p-2">
                  <div className="shadow-2 surface-card border-round p-4 flex-1 text-center mb-6 md:mb-0">
                     <div className="mb-4">
                        <img src="web/photos/image_ (2).png" width="280" height="200" className="-mt-8 border-round" alt="img" />
                     </div>
                     <p className="text-base line-height-3 text-justify" style={{ letterSpacing: '.1px' }}>
                     "Originally a high school, SBVD Sabha School has expanded to include junior and degree college programs. Our distinguished alumni include professionals in various fields, contributing globally while upholding our values and legacy. We pride ourselves on fostering academic excellence and holistic development."
                     </p>
                  </div>
               </div>
            </div>
         </div>

         {/* Alumni & Requirements Sections */}
         <CS_Alumni />
         <CS_Requirments />

         {/* Footer */}
         <div className="border-300 pt-5 text-center">
            <img src={Logo} alt="Image" height="96" />
            <div className="font-bold uppercase text-2xl flex justify-content-center flex-column align-items-center lg:flex-row" style={{ color: Color }}>
               {ProductName}
            </div>
            <ul className="flex justify-content-center gap-5 list-none">
               <li><NavLink to="/">{Home}</NavLink></li>
               <li><NavLink to="/gallery">{Menu2}</NavLink></li>
               <li><NavLink to="/blogs">{Menu3}</NavLink></li>
               <li><NavLink to="/contact">{Menu5}</NavLink></li>
            </ul>
         </div>
         <div className="fixed flex flex-wrap" style={{ bottom: '8%', justifyContent: 'center', right: '3%' }}>
            <img
               src="/web/photos/VNPride.png"
               alt="V N Pride"
               className="w-6rem md:w-10rem h-auto cursor-pointer shadow-3 bg-white border-round"
               onClick={fnNavigateVNPride}
            />
         </div>
      </div>
   );
}
